import React, { useEffect } from "react"
import Layout from "../../components/layout/Layout"
import Seo from "../../components/layout/Seo"

export default function Page() {
  useEffect(() => {
    ;(function (w, d, s, o, f, js, fjs) {
      w[o] =
        w[o] ||
        function () {
          ;(w[o].q = w[o].q || []).push(arguments)
        }
      js = d.createElement(s)
      fjs = d.getElementsByTagName(s)[0]
      js.id = o
      js.src = f
      js.async = 1
      fjs.parentNode.insertBefore(js, fjs)
    })(
      window,
      document,
      "script",
      "_hw",
      "https://files.withcherry.com/widgets/widget.js"
    )
    window._hw(
      "init",
      {
        debug: false,
        variables: {
          slug: "chamberlain-general-and-sedation-dentistry",
          name: "Chamberlain General and Sedation Dentistry",
        },
        styles: {
          primaryColor: "#00adee",
          secondaryColor: "#00adee10",
          fontFamily: "Montserrat",
        },
      },
      ["all", "hero", "howitworks", "testimony", "faq", "calculator"]
    )

    return () => {
      const scriptTag = document.getElementById("_hw")
      if (scriptTag) scriptTag.remove()
    }
  })
  return (
    <Layout path="/cherry-payment-plans">
      <Seo
        title="Cherry Payment Plans - Chamberlain Dental"
        description="Cherry helps you to start treatment that is needed with smaller monthly payments. Choose between 3,6,12,18 or 24 months payment plan with some qualifying for 0% APR options."
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Slabo+27px&family=Lato&family=Raleway&family=Montserrat&family=Oswald&family=Poppins&family=Source+Sans+Pro&family=PT+Sans&family=Open+Sans&display=swap"
        rel="stylesheet"
      />
      <div id="all"></div>
    </Layout>
  )
}
